@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #e40414;
  --secondary-color: #5f7d95;
  --hover-color: #494c69;
  --font-size: 14px;
  --font-size-large: 16px;
  --font-size-small: 12px;
  --text-color: #070600;
  --font-family: Poppins, Mukta Vaani, sans-serif;
  --button-background-color: var(--main-color);
  --button-text-color: #f6f6e9;
  --nav-background-color: var(--main-color);
  --nav-text-color: #f6f6e9;
  --border-color: var(--main-color);
  --hover-background-color: var(--hover-color);
  --hover-text-color: #f4f1de;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  margin: 0;
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #f6f6f5;
  scroll-behavior: smooth;
  user-select: none;
}

input,
select {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:disabled {
  cursor: not-allowed;
}

/* autoplay images */
.slider {
  /* height: 300px; */
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
}
.slider::before,
.slider::after {
  position: absolute;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

/*  IMPORTANT CODE BELOW */

.slide-track {
  width: calc(500px * 14);
  display: flex;
  animation: scroll 25s linear infinite;
  justify-content: space-between;
}

.slide {
  width: 500px;
  /* height: 250px; */
  margin: 20px;
  display: grid;
  place-items: center;
  transition: 1s;
  cursor: pointer;
}
.slide:hover {
  transform: scale(0.9);
}

img {
  aspect-ratio: 3/2;
  /* mix-blend-mode: color-burn; */
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-500px * 10));
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(250px * 20);
  }

  .slide {
    width: 250px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-250px * 10));
    }
  }
}
